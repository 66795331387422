import { useEffect, useMemo, useState } from "react";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Button, Form } from "semantic-ui-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { modalOperation } from "store/actions";
import ProjectDescription from "./ProjectDescription/ProjectDescription";
import GeneralInformation from "./GeneralInformation/GeneralInformation";
import Leadership from "./Leadership/Leadership";
import StaffingNeeds from "./StaffingNeeds/StaffingNeeds";
import History from "./History/History";
import { useSelector } from "react-redux";
import { getAddProjectDropdown, getEditProjectDetails, getProjectApprovalDetails, getProjectHistoryList, getProjectFinanceDetails, getEmailCodeResponseState, getAddEditProjectDetails, getPaSaveChangeDetails, getProjectUpdateState, getRevenueCreditNameState, getProjectSubmitReviewState, getUserPrivilageRolesState } from "store/selectors";
import { checkUserRoles, requestAddProjectDropdown, requestEditProject, requestProjectHistory, requestSaveChanges, requestSubmitForReview, requestSubmitToFinance, requestUpdateFinance, requestUserPrevilageRoles } from "store/rext";
import { getEditProjectFormValue, getNewProjectFormValue, scrollToTop, tranformProjectData } from "./utils";
import { useOktaAuth } from "@okta/okta-react";
import { getUserDetail } from "utils/oktaAuth";
import UsePeople from "HOC/UsePeople";
import { createProject, getEmailCode, getRevenueCredit, projectSubmitToPa, updateProject } from "store/list";
import { IPeopleUser } from "store/reducers/people";
import { toast } from 'react-toastify';
import Loading from "components/Loading/Loading";
import UsePrevious from "./UsePrevious";

const getFormValues = (loggedInUser: IPeopleUser, editData: any, isAddProject: boolean, additionalData: any, paId: string) => {
    if (Object.keys(editData).length !== 0 && !isAddProject) {
        return getEditProjectFormValue(loggedInUser, editData, additionalData);
    }
    return getNewProjectFormValue(loggedInUser, isAddProject, additionalData, paId);
}
const AddProject = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [isViewable, setIsViewable] = useState<boolean>(false);
    const [isApproval, setIsApproval] = useState<boolean>(false);
    const [isAddProject, setIsAddProject] = useState<boolean>(false);
    const [isLoadingApproved, setIsLoadingApproved] = useState<boolean>(false);
    const [isLoadingDenied, setIsLoadingDenied] = useState<boolean>(false);
    const [projectStatusDates, setProjectStatusDates] = useState<any>({
        generalInformation: {
            approvalDate: undefined,
            openSinceDate: undefined,
            proposalDate: undefined,
            requestStatus: undefined
        }
    });
    const [isLoadingEditData, setIsLoadingEditData] = useState<boolean>(false);
    const [userRole, setUserRole] = useState<string>('');
    const [enableReviewButton, setEnableReviewButton] = useState<boolean>(false);

    const { authState } = useOktaAuth();
    const { hrid } = getUserDetail(authState!);
    const { user } = UsePeople(hrid)

    const { data: dropDownData, fetching: dropdownFetching } = useSelector(getAddProjectDropdown);

    const { data: editProjectData, fetching } = useSelector(getEditProjectDetails);

    const { data: addEditProjectData, fetching: addEditFetching } = useSelector(getAddEditProjectDetails);

    const { data: projectSubmitToPaData, fetching: submitToPaFetching } = useSelector(getProjectApprovalDetails);

    const { fetching: projectPaSaveChangeFetching } = useSelector(getPaSaveChangeDetails);

    const { fetching: submitToFinanceFetching } = useSelector(getProjectFinanceDetails);

    const { data: emailCodeData, fetching: emailCodeFetching } = useSelector(getEmailCodeResponseState);

    const { fetching: financeUpdateFetching } = useSelector(getProjectUpdateState);

    const { data: projectHistoryData, fetching: fetchProjectHistory } = useSelector(getProjectHistoryList);

    const { data: revenueCreditData } = useSelector(getRevenueCreditNameState);

    const { fetching: fetchSubmitForReview, error: submitForReviewError } = useSelector(getProjectSubmitReviewState);

    const { data: userRolesDetails } = useSelector(getUserPrivilageRolesState);

    const wasSubmitForReviewUpdating = UsePrevious(fetchSubmitForReview);

    let paId = Number(searchParams.get("PA_ID"));
    let projectId = searchParams.get("projectID") || null;
    let paApprovalCode = searchParams.get("PA_Approval_Code") || null;
    let financeProjectCode = searchParams.get("Project_Code") || null;

    useEffect(() => {
        dispatch(requestAddProjectDropdown(paId));
        if (Object.keys(userRolesDetails).length === 0) {
            dispatch(requestUserPrevilageRoles());
        }
        if (paId !== 21) {
            dispatch(getRevenueCredit(paId));
        }
        if (projectId) {
            if (paApprovalCode !== null) {
                dispatch(getEmailCode(paApprovalCode));
            } else if (financeProjectCode !== null) {
                dispatch(getEmailCode(financeProjectCode));
            } else {
                dispatch(requestEditProject(projectId));
                setIsLoadingEditData(true)
            }
            dispatch(requestProjectHistory(projectId));
        }
    }, [dispatch])

    useEffect(() => {
        if (location.pathname == '/add-project') {
            setIsAddProject(true);
        }
        if (location.pathname == '/edit-project') {
            setIsEditable(true);
        }
        if (location.pathname == '/view-project') {
            setIsViewable(true);
        }
        scrollToTop();

        if (paId?.toString() === '12' || paId?.toString() === '17') {
            setEnableReviewButton(true);
        }
    }, [])

    const { register, control, handleSubmit, setValue, getValues, setError, clearErrors, reset, formState: { errors, defaultValues }, trigger, watch } = useForm({
        defaultValues: useMemo(() => {
            return getFormValues(user, {}, isAddProject, {}, paId.toString());
        }, [isAddProject]),
    });

    const submitToPa = handleSubmit(data => {
        const isSponsoringPaValid = sponsoringPaValidation();
        if (isSponsoringPaValid === '') {
            const payload = tranformProjectData(data, isEditable, editProjectData, isViewable);
            payload.generalInformation.paId = paId;
            payload.generalInformation.projectId = projectId;
            payload.generalInformation.projectStatus = null;
            payload.generalInformation.financeTeam = null;
            payload.submitToFinStatus = null;
            let buildUrlPath = `${window.location.origin}/edit-project?PA_ID=${paId}`;
            payload.url = buildUrlPath;
            if (Object.keys(errors).length == 0) {
                dispatch(projectSubmitToPa(payload));
                setTimeout(() => {
                    toast.info('Project Submitted to PA Successfully');
                    navigate('/')
                }, 3000);
            }
        } else {
            return openErrorModal(isSponsoringPaValid);
        }
    });

    const saveChanges = handleSubmit(data => {
        const isSponsoringPaValid = sponsoringPaValidation();
        if (isSponsoringPaValid === '') {
            const payload = tranformProjectData(data, isEditable, data, isViewable);
            payload.generalInformation.paId = paId;
            payload.generalInformation.projectId = projectId;
            payload.generalInformation.projectStatus = null;
            payload.generalInformation.financeTeam = null;
            payload.submitToFinStatus = null;
            let buildUrlPath = `${window.location.origin}/edit-project?PA_ID=${paId}`;
            payload.url = buildUrlPath;
            if (Object.keys(errors).length == 0) {
                dispatch(requestSaveChanges(payload));
            }

            setTimeout(() => {
                if (!projectPaSaveChangeFetching) {
                    toast.info('Changes saved successfully');
                    navigate('/')
                }
            }, 3000);
        } else {
            return openErrorModal(isSponsoringPaValid);
        }
    });

    const submitToFinance = handleSubmit(data => {
        const isSponsoringPaValid = sponsoringPaValidation();
        if (isSponsoringPaValid === '') {
            const payload = tranformProjectData(data, isEditable, editProjectData, isViewable);
            payload.generalInformation.paId = paId;
            payload.generalInformation.projectId = projectId;
            payload.generalInformation.projectStatus = null;
            payload.generalInformation.financeTeam = null;
            payload.submitToFinStatus = null;
            let buildUrlPath = `${window.location.origin}/edit-project?PA_ID=${paId}`;
            payload.url = buildUrlPath;
            if (Object.keys(errors).length == 0) {
                dispatch(requestSubmitToFinance(payload));
            }
            setTimeout(() => {
                if (!submitToFinanceFetching) {
                    toast.info('Project submitted to finance successfully');
                    navigate('/')
                }
            }, 3000);
        } else {
            return openErrorModal(isSponsoringPaValid);
        }
    });

    const watchFormData: any = watch();

    const submitFinanceActions = ((e: any, type: string) => {
        const isChargeCodeValid = chargeCodeValidation();
        const isSponsoringPaValid = sponsoringPaValidation();
        if (!isChargeCodeValid || !isSponsoringPaValid) {
            const payload = tranformProjectData(watchFormData, isEditable, emailCodeData, isViewable);
            payload.generalInformation.paId = paId;
            payload.generalInformation.projectId = projectId;
            payload.generalInformation.projectStatus = null;
            payload.generalInformation.financeTeam = user?.preferredName;
            payload.submitToFinStatus = null;
            let buildUrlPath = `${window.location.origin}/view-project?PA_ID=${paId}`;
            payload.url = buildUrlPath;
            if (type === "Denied") {
                setIsLoadingDenied(prev => !prev);
            } else {
                setIsLoadingApproved(approve => !approve);
            }
            dispatch(requestUpdateFinance(type, payload))
            setTimeout(() => {
                if (!financeUpdateFetching) {
                    toast.info(`Project ${type} successfully`);
                    setIsLoadingDenied(false)
                    setIsLoadingApproved(false)
                    navigate('/')
                }
            }, 3000);
        } else {
            let err = isChargeCodeValid !== '' ? isChargeCodeValid : isSponsoringPaValid;
            return openErrorModal(err);
        }
    });

    const chargeCodeValidation = () => {
        const numericOptionalDashRegex = /^[\d.-]+$/;
        let error = '';
        for (const sponsor of watchFormData.funding) {
            if (!numericOptionalDashRegex.test(sponsor.chargeCode)) {
                error = "Invalid Charge Code Format.";
            }
            if (sponsor.chargeCode == "" && (sponsor.paName !== "" || sponsor.paName !== null)) {
                error = "Charge Code Field Cannot be Blank.";
            }

        }
        return error;
    }

    const sponsoringPaValidation = () => {
        let error = '';
        for (const sponsor of watchFormData.funding) {
            if (sponsor.paName === '' && watchFormData.coSponsored !== 'N') {
                error = "For a co-sponsored project, co-sponsored PA is a mandatory field.";
            } else if ((sponsor.expenseFunded === '' || sponsor.timeFunded === '') && watchFormData.coSponsored !== 'N') {
                error = "For a co-sponsored project, Expense Funded/Time Funded is a mandatory field.";
            } else {
                error = '';
            }
        }
        return error;
    }

    const watchProjectName = useWatch({
        control,
        name: 'projectName',
    });

    const saveToDraft = (event: any) => {
        event.preventDefault();
        if (watchProjectName === '') {
            setError('projectName', {
                type: 'manual',
                message: 'Project name is required.',
            });
        } else {
            clearErrors('projectName');
        }
        const isSponsoringPaValid = sponsoringPaValidation();
        if (Object.keys(errors)?.length == 0) {
            if (isSponsoringPaValid === '') {
                const payload = tranformProjectData(watchFormData, isEditable, editProjectData, isViewable);
                payload.generalInformation.projectStatus = 'Draft';
                payload.generalInformation.financeTeam = null;
                if (projectId && isEditable) {
                    dispatch(updateProject(payload, projectId));
                } else {
                    dispatch(createProject(payload))
                }
                setTimeout(() => {
                    if (!addEditFetching) {
                        toast.info(addEditProjectData?.text);
                        navigate('/')
                    }
                }, 3000);
            } else {
                return openErrorModal(isSponsoringPaValid);
            }
        } else {
            handleErrors(errors);
        }
    };

    const handleErrors = (errors: any) => {
        const errData: any = {};

        // Iterate through the errors object
        for (const key in errors) {
            if (errors?.hasOwnProperty(key)) {
                const error = errors[key];
                if (Array.isArray(error)) {
                    // Handle array errors (e.g., staffField)
                    error.forEach((item, index) => {
                        for (const subKey in item) {
                            if (item.hasOwnProperty(subKey)) {
                                const subError = item[subKey];
                                if (subError.message) {
                                    errData[`${key}[${index}].${subKey}`] = subError.message;
                                }
                            }
                        }
                    });
                } else if (error.message) {
                    // Handle single field errors
                    errData[key] = error.message;
                }
            }
        }
        // Open the error modal with the constructed error data
        openMultipleErrorModal(errData);
    };

    const scrollInToError = () => {
        const errorField = document.querySelector('.error');
        if (errorField) {
            errorField.scrollIntoView({ behavior: 'smooth' });
        }
    }

    useEffect(() => {
        scrollInToError();
    }, [Object.keys(errors).length])

    const renderMessage = () => {
        return (
            <>
                <p>Are you sure you want to leave this page without saving?</p>
                <p>Click "OK" to return to the home page.</p>
                <p>Click "Cancel" to remain on this page.</p>
            </>
        )
    }
    const openCancelModal = () => {
        dispatch(modalOperation.showModal(ModalsType.AlertModal, {
            title: 'Alert',
            message: renderMessage(),
            secondaryBtnTitle: "Cancel",
            primaryBtnTitle: "Ok",
            onClose: () => {
                dispatch(modalOperation.hideModal())
            },
            onSave: () => {
                dispatch(modalOperation.hideModal());
                navigate(`/`);
            }
        }))
    }

    const openSubmitForReviewModal = handleSubmit(data => {
        dispatch(modalOperation.showModal(ModalsType.ReviewProjectModal, {
            onSelect: (data: any) => {
                const payload = tranformProjectData(watchFormData, isEditable, editProjectData, isViewable);
                let buildUrlPath = `${window.location.origin}/edit-project?PA_ID=${paId}`;
                payload.url = buildUrlPath;
                if (projectId) {
                    payload.generalInformation.projectId = projectId;
                    payload.generalInformation.paId = paId;
                }
                const finalPayload = {
                    ...payload,
                    leadershipAndFinancials: {
                        ...payload?.leadershipAndFinancials,
                        ...data
                    }
                };
                dispatch(requestSubmitForReview(finalPayload));
                dispatch(modalOperation.hideModal())
            }
        }))
    });

    const openErrorModal = (error: any) => {
        dispatch(modalOperation.showModal(ModalsType.AlertModal, {
            title: 'Alert!',
            message: error,
            onSave: () => {
                dispatch(modalOperation.hideModal());
            }
        }))
    }

    const openMultipleErrorModal = (errorDataArr: any) => {
        dispatch(modalOperation.showModal(ModalsType.AlertModal, {
            title: 'Alert!',
            message: (
                <ul className="list-type-none">
                    {Object.keys(errorDataArr).map((key, index) => (
                        <li key={index}>{errorDataArr[key]}</li>
                    ))}
                </ul>
            ),
            onSave: () => {
                dispatch(modalOperation.hideModal());
            }
        }))
    }

    useEffect(() => {
        if (((isEditable || isViewable) && paApprovalCode == null && financeProjectCode == null)) {
            reset(getFormValues(user, editProjectData, isAddProject, revenueCreditData, ''));
            setProjectStatusDates(editProjectData)
        }
    }, [user, editProjectData]);

    useEffect(() => {
        if (paApprovalCode !== null && financeProjectCode == null && Object.keys(emailCodeData).length !== 0) {
            setIsApproval(true);
            reset(getFormValues(user, emailCodeData, isAddProject, revenueCreditData, ''));
            setProjectStatusDates(emailCodeData)
        }
        if (paApprovalCode == null && financeProjectCode !== null && Object.keys(emailCodeData).length !== 0) {
            setIsApproval(true);
            reset(getFormValues(user, emailCodeData, isAddProject, revenueCreditData, ''));
            setProjectStatusDates(emailCodeData)
        }
    }, [user, emailCodeData]);

    useEffect(() => {
        if (location.pathname == '/add-project') {
            setValue(`paManagementContact`, user)
        }
        if (isAddProject) {
            reset(getFormValues(user, {}, isAddProject, paId !== 21 ? revenueCreditData : '', paId.toString()));
        }
        setValue(`funding`, []);
        // setValue(`funding.${0}.paName`, paId.toString());
    }, [isAddProject, user]);

    useEffect(() => {
        if (wasSubmitForReviewUpdating && !fetchSubmitForReview) {
            if (!submitForReviewError) {
                toast.info('Project Submitted for Review Successfully');
                navigate('/')
            } else if (submitForReviewError) {
                toast.error("Something went wrong!");
            }
        }
    }, [wasSubmitForReviewUpdating, fetchSubmitForReview])

    const disableSubmitToPa = () => {
        if ((watchFormData.coSponsored === 'N') || (projectStatusDates.generalInformation?.requestStatus === 'PA Approved')) {
            return false;
        }
        // if ((watchFormData.coSponsored === 'Y') && (projectStatusDates.generalInformation?.requestStatus === 'Awaiting PA Approval') && (projectStatusDates.generalInformation?.requestStatus !== 'PA Approved')) {
        //     return true;
        // }
        return true;
    }

    useEffect(() => {
        if (Object.keys(userRolesDetails)?.length) {
            const roles = checkUserRoles(userRolesDetails);
            setUserRole(roles);
        }
    }, [userRolesDetails]);

    const renderButtonsIfNonAdminNonAd = () => {
        return (
            <>
                {enableReviewButton && (
                    <li>
                        <Button type="button" className="standard" size="mini"
                            onClick={openSubmitForReviewModal}
                            loading={fetchSubmitForReview || false}
                        >Submit for Review</Button>
                    </li>
                )}
            </>
        )
    }

    const renderButtonsIfAdminOrAdNonCoSponser = () => {
        return (
            <>
                {enableReviewButton && (
                    <li>
                        <Button type="button" className="standard" size="mini"
                            onClick={openSubmitForReviewModal}
                            loading={fetchSubmitForReview || false}
                        >Submit for Review</Button>
                    </li>
                )}
                <li>
                    <Button
                        onClick={(e) => submitToFinance(e)} loading={submitToFinanceFetching || false} className="standard" size="mini"
                    >Submit to Finance</Button>
                </li>
            </>
        )
    }

    const renderButtonsIfAdminOrAdCoSponser = () => {
        return (
            <>
                {enableReviewButton && (
                    <li>
                        <Button type="button" className="standard" size="mini"
                            onClick={openSubmitForReviewModal}
                            loading={fetchSubmitForReview || false}
                        >Submit for Review</Button>
                    </li>
                )}
                <li>
                    <Button
                        onClick={(e) => submitToPa(e)} loading={submitToPaFetching || false}
                        //projectStatusDates.generalInformation?.requestStatus === 'Awaiting PA Approval'
                        disabled={(projectStatusDates.generalInformation?.requestStatus === 'PA Approved') ? true : false}
                        className="standard" size="mini">Submit to Co-Sponsor PAs</Button>
                </li>
                <li>
                    <Button
                        onClick={(e) => submitToFinance(e)} loading={submitToFinanceFetching || false} className="standard" size="mini"
                        disabled={(projectStatusDates.generalInformation?.requestStatus === 'PA Approved') ? false : true}
                    >Submit to Finance</Button>
                </li>
            </>
        )
    }

    const renderButtonsIfCoSponserAndNonAd = () => {
        return (
            <li>
                <Button
                    onClick={(e) => submitToPa(e)} loading={submitToPaFetching || false}
                    disabled={(projectStatusDates.generalInformation?.requestStatus === 'PA Approved') ? true : false}
                    className="standard" size="mini">Submit to Co-Sponsor PAs</Button>
            </li>
        );
    }

    const renderProjectActionButtons = () => {
        return (
            <>
                {isViewable ? (
                    <ul className="horizontal-list">
                        <li>
                            <Button type="button" className="standard" size="mini" onClick={() => navigate(`/`)}>Go to Innovation Database home</Button>
                        </li>
                    </ul>
                ) : (
                    <ul className="horizontal-list">
                        <li>
                            <Button type="button" className="standard" size="mini" onClick={openCancelModal}>Cancel</Button>
                        </li>

                        {!isApproval && financeProjectCode == null && (
                            <>
                                <li>
                                    <Button onClick={(e) => saveToDraft(e)} loading={addEditFetching || false} className="standard" size="mini">Save Draft</Button>
                                </li>
                            </>
                        )}

                        {(userRole === 'Non AD Group Access' || userRole === 'No Access') && !financeProjectCode && !paApprovalCode && renderButtonsIfNonAdminNonAd()}

                        {((userRole === 'Non AD Group Access' || userRole === 'No Access') && watchFormData.coSponsored === 'Y') && !financeProjectCode && !paApprovalCode && renderButtonsIfCoSponserAndNonAd()}

                        {(userRole === 'AD Group Access' || userRole === 'All Access') && watchFormData.coSponsored === 'N' && !financeProjectCode && !paApprovalCode && !projectId && renderButtonsIfAdminOrAdNonCoSponser()}

                        {(userRole === 'AD Group Access' || userRole === 'All Access') && watchFormData.coSponsored === 'Y' && !financeProjectCode && !paApprovalCode && renderButtonsIfAdminOrAdCoSponser()}

                        {(userRole === 'AD Group Access' || userRole === 'All Access') && watchFormData.coSponsored === 'N' && !financeProjectCode && !paApprovalCode && projectId && renderButtonsIfAdminOrAdNonCoSponser()}

                        {isApproval && financeProjectCode == null && (
                            <li>
                                <Button type="button"
                                    onClick={(e) => saveChanges(e)} loading={projectPaSaveChangeFetching || false}
                                    className="standard" size="mini"
                                    disabled={userRole === 'Non AD Group Access' || userRole === 'No Access'}
                                >Save Changes</Button>
                            </li>
                        )}
                        {financeProjectCode !== null && (
                            <>
                                <li>
                                    <Button type="button"
                                        onClick={(e) => submitFinanceActions(e, 'Denied')} loading={isLoadingDenied} className="standard" size="mini"
                                        disabled={userRole === 'Non AD Group Access' || userRole === 'No Access'}
                                    >Deny</Button>
                                </li>
                                <li>
                                    <Button type="button"
                                        onClick={(e) => submitFinanceActions(e, 'Approved')} loading={isLoadingApproved} className="standard" size="mini"
                                        disabled={userRole === 'Non AD Group Access' || userRole === 'No Access'}
                                    >Approve</Button>
                                </li>
                            </>
                        )}
                    </ul>
                )}
            </>
        )
    }

    return (
        <div className="padding-l-14 padding-r-14 margin-t-6">

            <div className="width-100">
                {/* onSubmit={onSubmit} */}
                <Form size="small">
                    <div className="flex flex-justify-between">
                        <div className="flex-justify-start text-md">
                            <span className="text-danger-color">*</span> = *required
                        </div>

                        <div className="flex-justify-end">
                            <>{renderProjectActionButtons()}</>
                        </div>
                    </div>

                    <div className={`margin-t-4 ${isViewable ? 'is-view-mode' : ''}`}>
                        {!fetching && !emailCodeFetching && (
                            <GeneralInformation register={register} errors={errors} getValues={getValues} setValue={setValue} trigger={trigger} reset={reset} control={control} watch={watch} dropDownData={dropDownData.dropdowns} paId={paId} isEditable={isEditable} editData={editProjectData || emailCodeData} isViewMode={isViewable} financeProjectCode={financeProjectCode} dates={projectStatusDates} fetching={fetching} paApprovalCode={paApprovalCode} isAddProject={isAddProject} revenueMDP={revenueCreditData} />
                        )}

                        <ProjectDescription register={register} errors={errors} getValues={getValues} setValue={setValue} trigger={trigger} reset={reset} control={control} isEditable={isEditable} editData={editProjectData} isViewMode={isViewable} />

                        <Leadership register={register} errors={errors} getValues={getValues} setValue={setValue} trigger={trigger} reset={reset} control={control} setError={setError} paId={paId} dropDownData={dropDownData.dropdowns} editData={!isApproval ? editProjectData : projectSubmitToPaData} emailCodeData={emailCodeData} isEditable={isEditable} isViewMode={isViewable} />

                        <StaffingNeeds register={register} errors={errors} getValues={getValues} setValue={setValue} trigger={trigger} reset={reset} control={control} dropDownData={dropDownData.dropdowns} editData={watchFormData} isEditable={isEditable} fetching={fetching} isViewMode={isViewable} setError={setError} clearErrors={clearErrors} />

                        {/* <PortfolioInformation register={register} errors={errors} getValues={getValues} setValue={setValue} trigger={trigger} reset={reset} control={control} dropDownData={dropDownData.dropdowns} isViewMode={isViewable} /> */}
                    </div>

                    <div className="flex flex-justify-end">
                        <div className="flex-justify-end margin-3">
                            <>{renderProjectActionButtons()}</>
                        </div>
                    </div>
                </Form>

                {!fetchProjectHistory && projectHistoryData.length > 0 && window.location.pathname !== '/add-project' && (
                    <>
                        <History data={projectHistoryData} />
                    </>
                )}
                {fetchProjectHistory && (
                    <>
                        <Loading />
                    </>
                )}
            </div>

            {(wasSubmitForReviewUpdating || fetchSubmitForReview) && <Loading isGlobal />}
        </div>
    )
}

export default AddProject;